import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Customer } from '../models/Customer';
import { CustomerHierarchy } from '../models/CustomerHierarchy';

import {
  filter,
  debounceTime,
  distinctUntilChanged,
  switchMap
} from 'rxjs/operators';
import { StartupService } from './startup.service';
import { CustomerRateOption } from '../models/CustomerRateOption';
import { CustomerConfiguration } from '../models/CustomerConfiguration';
import { BulkCreateCustomerBuyRateSettingResponse, CreateCustomerBuyRateSettingRequest, CustomerBuyRateSetting, UpdateCustomerBuyRateSettingRequest } from "../models/CustomerBuyRateSetting";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  private requestHeaders: HttpHeaders;

  constructor(
    private auth: AuthService,
    private http: HttpClient,
    private startupService: StartupService
  ) {
    const enterpriseID =
      this.auth.BlueShipUser && this.auth.BlueShipUser.enterpriseID
        ? this.auth.BlueShipUser.enterpriseID.toString()
        : null;
    const userID =
      this.auth.BlueShipUser && this.auth.BlueShipUser.userID
        ? this.auth.BlueShipUser.userID.toString()
        : null;
    const userName = this.auth.BlueShipUser
      ? this.auth.BlueShipUser.userName
      : null;

    this.requestHeaders = new HttpHeaders({
      EnterpriseID: enterpriseID,
      UserID: userID,
      UserName: userName,
    });
  }

  getByID(customerID: string): Observable<Customer> {
    return this.http.get<Customer>(
      `${this.startupService.ratingApiUrl}/api/customer/${customerID}`
    );
  }

  getCustomerByAccountNumber(accountNumber: string) {
    return this.http.get<Customer>(`${this.startupService.ratingApiUrl}/api/Customer/getbyaccountNumber?accountNumber=${accountNumber}`);
  }

  getCustomers(): Observable<Customer[]> {
    return this.http.get<Customer[]>(
      `${this.startupService.ratingApiUrl}/api/customer/all`
    );
  }

  search(search$: Observable<string>): Observable<Customer[]> {
    return search$.pipe(
      filter(res => res.length > 2),
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => this.searchGet(term))
    );
  }

  getCustomerHierarchy(customerID: number): Observable<CustomerHierarchy[]> {
    return this.http.get<CustomerHierarchy[]>(
      `${this.startupService.ratingApiUrl}/api/customer/hierarchy?customerId=${customerID}`
    );
  }

  getCustomerChildren(customerID: number): Observable<CustomerHierarchy[]> {
    return this.http.get<CustomerHierarchy[]>(
      `${this.startupService.ratingApiUrl}/api/customer/children?customerId=${customerID}`
    );
  }

  private searchGet(searchString: string): Observable<Customer[]> {
    return this.http.get<Customer[]>(
      `${this.startupService.ratingApiUrl}/api/customer/search?searchString=${searchString}`
    );
  }
  createCustomer(customer: Customer){
    customer.isTestAccount = true;
    return this.http.post(`${this.startupService.ratingApiUrl}/api/customer`, customer);
  }

  updateCustomer(customer: Customer){
    customer.isTestAccount = true;
    return this.http.put(`${this.startupService.ratingApiUrl}/api/customer`, customer);
  }

  insertCustomerRateOption(rateOption: CustomerRateOption){
    return this.http.post(`${this.startupService.ratingApiUrl}/api/CustomerRateOption`, rateOption);
  }

  getCustomerRateOptions(id: number){
    return this.http.get<CustomerRateOption[]>(`${this.startupService.ratingApiUrl}/api/CustomerRateOption/customer?customerID=${id}`);
  }

  editCustomerRateOption(rateOption: CustomerRateOption){
    return this.http.put(`${this.startupService.ratingApiUrl}/api/CustomerRateOption`, rateOption);
  }

  deleteCustomerRateOption(id: number){
    return this.http.delete(`${this.startupService.ratingApiUrl}/api/CustomerRateOption/${id}`);
  }

  getCustomerConfigurations(id: number){
    return this.http.get<CustomerConfiguration[]>(`${this.startupService.ratingApiUrl}/api/CustomerConfiguration/all?customerID=${id}`);
  }

  insertNewCustomerConfiguration(customerConfig: CustomerConfiguration){
    return this.http.post(`${this.startupService.ratingApiUrl}/api/CustomerConfiguration`, customerConfig);
  }

  updateCustomerConfiguration(customerConfig: CustomerConfiguration){
    return this.http.put(`${this.startupService.ratingApiUrl}/api/CustomerConfiguration`, customerConfig);
  }

  deleteCustomerConfiguration(id: number){
    return this.http.delete(`${this.startupService.ratingApiUrl}/api/CustomerConfiguration/${id}`);
  }

  getCustomerBuyRateSettingsByCustomer(customerId: number): Observable<CustomerBuyRateSetting[]> {
    return this.http.get<CustomerBuyRateSetting[]>(
      `${this.startupService.ratingApiUrl}/api/CustomerBuyRateSetting/customer/${customerId}`,
      {
        headers: this.requestHeaders,
      }
    );
  }

  getCustomerBuyRateSetting(customerBuyRateSettingId: number): Observable<CustomerBuyRateSetting> {
    return this.http.get<CustomerBuyRateSetting>(
      `${this.startupService.ratingApiUrl}/api/CustomerBuyRateSetting/${customerBuyRateSettingId}`,
      {
        headers: this.requestHeaders,
      }
    );
  }

  createCustomerBuyRateSetting(customerBuyRateSetting: CreateCustomerBuyRateSettingRequest): Observable<CustomerBuyRateSetting>{
    return this.http.post<CustomerBuyRateSetting>(
      `${this.startupService.ratingApiUrl}/api/CustomerBuyRateSetting`,
      customerBuyRateSetting,
      {
        headers: this.requestHeaders,
      }
    );
  }
  bulkCreateCustomerBuyRateSetting(customerBuyRateSettings: CreateCustomerBuyRateSettingRequest[]): Observable<BulkCreateCustomerBuyRateSettingResponse>{
    return this.http.post<BulkCreateCustomerBuyRateSettingResponse>(
      `${this.startupService.ratingApiUrl}/api/CustomerBuyRateSetting/bulk`,
      customerBuyRateSettings,
      {
        headers: this.requestHeaders,
      }
    );
  }

  updateCustomerBuyRateSetting(customerBuyRateSetting: UpdateCustomerBuyRateSettingRequest): Observable<CustomerBuyRateSetting> {
    return this.http.put<CustomerBuyRateSetting>(
      `${this.startupService.ratingApiUrl}/api/CustomerBuyRateSetting`,
      customerBuyRateSetting,
      {
        headers: this.requestHeaders,
      }
    );
  }

  deleteCustomerBuyRateSetting(customerBuyRateSettingId: number) {
    return this.http.delete(
      `${this.startupService.ratingApiUrl}/api/CustomerBuyRateSetting/${customerBuyRateSettingId}`,
      {
        headers: this.requestHeaders,
      }
    );
  }
}
